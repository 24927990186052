import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "./App";
import "./index.less";
import "view-design/dist/styles/iview.css";
import { Message } from "view-design";

Vue.config.productionTip = false;
Vue.filter("download", (link) => {
  if (!link) return null;
  const arr = link.split("/");
  const fileName = arr[arr.length - 1];
  let url = process.env.VUE_APP_API_DOMAIN + "/doc/download/" + fileName;
  return url;
});
Message.config({ top: 300, duration: 5 });
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
